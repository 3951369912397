const serviceGuideList = [
  {
    name: "想咨询",
    imgUrl: require("./img/mediate.png"),
    bannerImgUrl: require("./img/xzx.png"),
    list: [
      {
        name: "明楼街道公共法律服务站",
        tel: "15967869507",
        address:
          "现场咨询：每周一、周四下午  明楼街道公共法律服务站(宁波市鄞州区曙光北路218弄1号）",
      },
      {
        name: "明东社区公共法律服务点",
        tel: "18767148382",
        address: "现场咨询（预约制）",
      },
      {
        name: "明南社区公共法律服务点",
        tel: "13566610259",
        address: "现场咨询（预约制）",
      },
      {
        name: "明北社区公共法律服务点",
        tel: "18767148382",
        address: "现场咨询（预约制）",
      },
      {
        name: "常青藤社区公共法律服务点",
        tel: "15988617765",
        address: "现场咨询（预约制）",
      },
      {
        name: "朝晖社区公共法律服务点",
        tel: "18006656098",
        address: "现场咨询（预约制）",
      },
      {
        name: "徐家社区公共法律服务点",
        tel: "15088402259",
        address: "现场咨询（预约制）",
      },
      {
        name: "徐戎社区公共法律服务点",
        tel: "18006656098",
        address: "现场咨询（预约制）",
      },
      {
        name: "东海社区公共法律服务点",
        tel: "15372655632",
        address: "现场咨询（预约制）",
      },
      {
        name: "惊驾社区公共法律服务点",
        tel: "13777098841",
        address: "现场咨询（预约制）",
      },
      {
        name: "庆丰社区公共法律服务点",
        tel: "15988617765",
        address: "现场咨询（预约制）",
      },
      {
        name: "和丰社区公共法律服务点",
        tel: "15988617765",
        address: "现场咨询（预约制）",
      },
    ],
  },
  {
    name: "找调解",
    imgUrl: require("./img/tiaojie.png"),
    bannerImgUrl: require("./img/ztj.png"),
    list: [
      {
        name: "明楼街道人民调解委员会",
        tel: "0574-87728661",
        address: "宁波市鄞州区曙光北路218弄1号",
      },
      {
        name: "明东社区人民调解委员会",
        tel: "0574-8768060",
        address: "宁波市鄞州区吉庆街40号",
      },
      {
        name: "明北社区人民调解委员会",
        tel: "0574-87761984",
        address: "宁波市鄞州区朝晖路341号",
      },
      {
        name: "常青藤社区人民调解委员会",
        tel: "0574-87786337",
        address: "宁波市鄞州区朝晖路416弄117号",
      },
      {
        name: "和丰社区人民调解委员会",
        tel: "0574-88298865",
        address: "宁波市鄞州区江东北路458号",
      },
      {
        name: "庆丰社区人民调解委员会",
        tel: "0574-87774911",
        address: "宁波市鄞州区曙光北路惊驾名庭B区10幢23号",
      },
      {
        name: "朝晖社区人民调解委员会",
        tel: "0574-87720296",
        address: "宁波市鄞州区朝晖路181弄25-29号",
      },
      {
        name: "徐戎社区人民调解委员会",
        tel: "0574-87337369",
        address: "宁波市鄞州区曙光北路101号对面",
      },
      {
        name: "明南社区人民调解委员会",
        tel: "0574-87775617",
        address: "宁波市鄞州区华中街67弄8号",
      },
      {
        name: "惊驾社区人民调解委员会",
        tel: "0574-87335285",
        address: "宁波市鄞州区惊驾路15弄9号",
      },
      {
        name: "徐家社区人民调解委员会",
        tel: "0574-83027877",
        address: "宁波市鄞州区宁徐路191弄7号",
      },
      {
        name: "东海社区人民调解委员会",
        tel: "0574-87760487",
        address: "宁波市鄞州区民安路181弄29号",
      },
      {
        name: "缤丰社区人民调解委员会",
        tel: "0574-88037170",
        address: "宁波市鄞州区丰昌路52号",
      },
    ],
  },
  {
    name: "求援助",
    imgUrl: require("./img/legalAid.png"),
    bannerImgUrl: require("./img/qyz.png"),
    list: [
      {
        name: "法律援助咨询",
        tel: "12348",
        address: "",
      },
      {
        name: "鄞州区法律援助中心",
        tel: "11号、12号窗口现场办理",
        address: "宁波市鄞州区首南西路1号",
      },
      {
        name: "明楼街道法律援助工作站",
        tel: "0574-88367867",
        address: "宁波市鄞州区曙光北路218弄1号明楼司法所二楼204室",
      },
    ],
  },
  {
    name: "办公证",
    imgUrl: require("./img/court.png"),
    bannerImgUrl: require("./img/bgz.png"),
    list: [
      {
        name: "浙江省宁波市永欣公证处",
        tel: "0574-87758869",
        address: "宁波市鄞州区江东北路277号财富中心主楼2单元2楼",
      },
      {
        name: "浙江省宁波市鄞源公证处",
        tel: "0574-89216607",
        address: "宁波市鄞州区南部商务区天童南路639号永强大厦5楼",
      },
    ],
  },
  {
    name: "要仲裁",
    imgUrl: require("./img/notarizationEidCard.png"),
    bannerImgUrl: require("./img/yzc.png"),
    list: [
      {
        name: "明楼街道劳动人事争议调解中心",
        tel: "0574-87792533",
        address: "宁波市鄞州区曙光北路218弄3号",
      },
      {
        name: "鄞州区劳动人事争议仲裁院",
        tel: "0574-89293274",
        address: "宁波市鄞州区惠风东路225号",
      },
    ],
  },
  {
    name: "寻鉴定",
    imgUrl: require("./img/judicialExpertise.png"),
    bannerImgUrl: require("./img/xjd.png"),
    list: [
      {
        name: "宁波天童司法鉴定中心",
        tel: "0574-83870571",
        address: "宁波市鄞州区福明路769号",
      },
    ],
  },
  {
    name: "提复议",
    imgUrl: require("./img/communityCorrection.png"),
    bannerImgUrl: require("./img/tfy.png"),
    list: [
      {
        name: "明楼街道街道办事处",
        tel: "0574-88367867",
        address: "宁波市鄞州区曙光北路218弄1号",
      },
      {
        name: "鄞州区司法局",
        tel: "0574-89189483",
        address: "宁波市鄞州区惠风东路668号A楼",
      },
    ],
  },
  {
    name: "打官司",
    imgUrl: require("./img/lawyer.png"),
    bannerImgUrl: require("./img/dgs.png"),
    list: [
      {
        name: "鄞州区人民法院福明法庭",
        tel: "0574-87976313",
        address: "宁波市鄞州区达升路261弄",
      },
      {
        name: "鄞州区人民法院",
        tel: "0574-89051779",
        address: "宁波市鄞州区惠风西路88号",
      },
    ],
  },
];
export { serviceGuideList };
