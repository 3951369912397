<template>
  <div class="page">
    <div class="topBox">
      <img :src="detail.bannerImgUrl" alt="" />
    </div>
    <div class="list">
      <div class="item" v-for="(item, index) in detail.list" :key="index">
        <div class="title">{{ item.name }}</div>
        <img class="tel" src="./img/tel.png" alt="" />
        <div class="call" @click="callSomeOne(item)">拨打电话</div>
        <img class="arrow" src="./img/arrow.png" alt="" />
      </div>
    </div>
    <van-overlay :show="show" @click="show = false">
      <div class="wrapper">
        <div class="dialog">
          <div class="title">{{ selectItem.name }}</div>
          <div class="address" v-if="selectItem.address != ''">
            地址：{{ selectItem.address }}
          </div>
          <div class="tel" v-if="selectItem.tel != ''">
            <span v-if="selectItem.name != '鄞州区法律援助中心'"
              >联系电话：</span
            >{{ selectItem.tel }}
          </div>
          <div class="btnBox">
            <div class="backBtn" @click.stop="show = false">返回</div>
            <div
              class="noTelBtn"
              v-if="selectItem.name == '鄞州区法律援助中心'"
            >
              拨号
            </div>
            <div class="callBtn" v-else @click.stop="callPhoneNum">拨号</div>
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { serviceGuideList } from "./map.js";
export default {
  name: "lawServiceGuideDetail",
  data() {
    return {
      show: false,
      serviceGuideList,
      detail: {},
      selectItem: {},
    };
  },
  created() {
    this.serviceGuideList.forEach((item) => {
      if (item.name == this.$route.query.titleName) {
        this.detail = item;
      }
    });
    console.log(this.detail);
  },
  methods: {
    callSomeOne(item) {
      this.selectItem = item;
      this.show = true;
    },
    callPhoneNum() {
      let _this = this;
      window.location.href = `tel://${_this.selectItem.tel}`;
    },
  },
};
</script>

<style scoped lang="less">
.page {
  min-height: 100vh;
  width: 100%;
  box-sizing: border-box;
  background: #fff;
  .topBox {
    width: 100%;
    height: 372px;
    box-sizing: border-box;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .list {
    width: 100%;
    height: auto;
    padding: 0 30px 50px;
    box-sizing: border-box;
    background: #ffffff;
    border-radius: 16px;
    margin-top: -20px;
    .item {
      width: 100%;
      height: 116px;
      border-bottom: 2px solid rgba(129, 129, 129, 0.1);
      box-sizing: border-box;
      display: flex;
      align-items: center;
      .title {
        font-size: 32px;
        color: #343332;
        flex: 1;
      }
      .tel {
        width: 24px;
      }
      .call {
        margin-left: 4px;
        font-size: 22px;
        color: #367af5;
      }
      .arrow {
        margin-left: 8px;
        width: 12px;
      }
    }
    .item:nth-last-child(1) {
      border: none;
    }
  }
  .wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    .dialog {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      box-sizing: border-box;
      background-color: #fff;
      padding: 102px 56px 132px;
      .title {
        font-size: 28px;
        font-weight: 600;
        color: #343332;
        padding-bottom: 40px;
      }
      .address {
        font-size: 28px;
        color: rgba(77, 77, 77, 0.85);
        padding-bottom: 14px;
      }
      .tel {
        font-size: 28px;
        color: rgba(77, 77, 77, 0.85);
        padding-bottom: 104px;
      }
      .btnBox {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .backBtn {
          width: 280px;
          height: 80px;
          border-radius: 48px;
          border: 2px solid #007eff;
          text-align: center;
          line-height: 80px;
          font-size: 28px;
          font-weight: 600;
          color: #007eff;
          box-sizing: border-box;
        }
        .callBtn {
          width: 280px;
          height: 80px;
          border-radius: 48px;
          background: #007eff;
          text-align: center;
          line-height: 80px;
          font-size: 28px;
          font-weight: 600;
          color: #ffffff;
          box-sizing: border-box;
        }
        .noTelBtn {
          width: 280px;
          height: 80px;
          border-radius: 48px;
          background: #ccc;
          text-align: center;
          line-height: 80px;
          font-size: 28px;
          font-weight: 600;
          color: #ffffff;
          box-sizing: border-box;
        }
      }
    }
  }
}
</style>
